import React, { Component } from 'react';
import banneryellow from '../img/shape/BG-1.png'


class Avis extends Component {

    render() {

        return (
            <section className="testimonial__area pt-50 pb-50" style={{ backgroundImage: `url(${'assets/img/testimonial/home-3/testimonial-bg-3.jpg'})` }}>
                <div className="container">
                    <div className="row justify-content-center align-items-center"> 
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="testimonial__video-content d-sm-flex pl-10"> 
                                    <div className="testimonial__video-text">
                                        <div className='section__title-wrapper'>
                                            <h2 className="section__title">
                                            Atouts 
                                            <span className="yellow-bg-big">  de 
                                            <img src={banneryellow} alt="img not found" /> la Formation
                                            </span>
                                            </h2>
                                    </div>
                                        <ul className='pl-40 pt-30'>
                                            <li className='Benificer'>
                                            Une formation unique au Maroc;
                                            </li>
                                            <li className='Benificer'>
                                            Une formation pluridisciplinaire de haut niveau
                                            </li>
                                            <li className='Benificer'>
                                             Un corps professoral expert national et international
                                            </li>
                                            <li className='Benificer'>
                                             Un campus résidentiel et des équipements modernes
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="testimonial__video fix">
                                <div className="testimonial__thumb-3">
                                    <iframe src="https://www.youtube.com/embed/VK_NSxoiE1Y" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                                </div>
                           </div>
                          </div> 
                       
                            </div>
                        </div>
            </section>
        );
    }
}

export default Avis;